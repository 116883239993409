import { LogLevelEnum } from '../app/shared/models/enums/log-level.enum'

export const environment = {
   production: false,
   logLevel: LogLevelEnum.DEBUG,
   API_URL: 'http://localhost:3000',

   KEYCLOAK_AUTH_URL: 'https://sso-dev.innen.it',
   KEYCLOAK_REALM_NAME: 'PNO_MASTER_DEV',
   KEYCLOAK_CLIENT_ID: 'showcase-dev-angular',

   LINKING_TOOL_URL: 'https://pno-linkingtool-dev.innen.it',
   PMP_IN_PREPARATION_URL: 'https://pmp-test2020-third.innovationplace.eu',
}
